// ==============================
//* HIGHLIGHT CONTENT / SLIDER MOBILE
//
//? This file manages mobile highlight content slider behavior.
// ==============================

$(() => {
    const hightlightSlider = ".highlight-row";

    for (let i = 0; i < $(hightlightSlider).length; i++) {
        const slider = $(hightlightSlider)[i];
        const sliderWrapper = $(slider).closest(".slider-wrapper");
        const numberComponents = $(slider).children().length;
        const dataMd = $(sliderWrapper).attr("data-md");

        if (dataMd > numberComponents) $(slider).closest(".slider-wrapper").attr("data-md", numberComponents);
        $(slider).attr("data-components", numberComponents);
    }
});
